import "./RsvpForm.css"
import { useState, useEffect } from "react";
import { useLocation, useNavigate } from 'react-router-dom';

function RsvpForm() {
  const location = useLocation();
  const navigate = useNavigate();
  const [doneResponding, setDoneResponding] = useState(false);
  const [guestInfo, setGuestInfo] = useState(location?.state?.responses || [{ name: "", attending: true }]);

  useEffect(() => {
    if (location?.state?.fromRsvp !== true) {
      navigate('/rsvp');
    }
  });

  function addGuest() {
    setGuestInfo([...guestInfo, { name: "", attending: true }]);
  }

  function handleInputChange (index, field, value) {
    const updatedGuestInfo = [...guestInfo];
    updatedGuestInfo[index][field] = value;
    setGuestInfo(updatedGuestInfo);
  };

  function removeGuest(index) {
    const newGuestInfo = [...guestInfo.slice(0, index), ...guestInfo.slice(index + 1)];
    setGuestInfo(newGuestInfo);
  }

  function toggleModal() {
    setDoneResponding(!doneResponding);
  }

  function navigateToSuccessPage() {
    navigate('/rsvp-success', { replace: true });
  }

  function navigateToErrorPage() {
    navigate('/rsvp-error', { replace: true });
  }

  function submitRsvp() {
    if (location?.state?.email === undefined || location?.state?.name === undefined) { 
      navigateToErrorPage(); 
    }
    const data = { dataEmail: location.state.email, dataName: location.state.name, dataInfo: guestInfo };
    const request = new Request('/api/rsvpResponder', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    });
    const func = async () => {
      fetch(request)
        .then(response => response.json())
        .then(data => {
          navigateToSuccessPage();
        })
        .catch(error => {
          navigateToErrorPage();
        });
    }
    func();
  }

  return (
    <>
      <style>{`body { background-color: var(--purple); }`}</style>
      <div className='header_div'>
        <h2 className='header_text_rsvp'>Rsvp Form</h2>
      </div>
      <div className="body_div">
        <p className="rsvp_p">
          Please submit a response for each member of your household.
        </p>
        <p className="rsvp_p">
          Due to limited capacity at the venue, we are unable to accomodate anyone under the age of 18. 
          Thank you for understanding.
        </p>
        <ul style={{ display:'flex', flexDirection:'column', alignSelf:'start', padding:0, margin: 0}}>
          {guestInfo.map((guest, index) => (
          <li key={index} style={{ listStyleType: 'none' }}>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems:'flex-start', marginTop:"1em", marginBottom:"10%" }} >
              <p className="form_p">First and last name</p>
              <input 
                type="text"
                value={guest.name} 
                onChange={(e) => handleInputChange(index, 'name', e.target.value)} 
                className="form_text_input"
              />
              <p className="form_p">Are they attending?</p>
              <div style={{display: 'flex', flexDirection:'row', alignItems:'center', marginBottom: '0.5em'}}>
                <p className="form_p" style={{marginRight: '0.2em', marginBottom: 0}}>Yes</p>
                <input 
                  type="checkbox"
                  checked={guest.attending}
                  onChange={(e) => handleInputChange(index, 'attending', true)} 
                  className="checkbox"
                />
                <p className="form_p" style={{marginLeft: '1em', marginRight: '0.2em', marginBottom: 0}}>No</p>
                <input 
                  type="checkbox"
                  checked={!guest.attending}
                  onChange={(e) => handleInputChange(index, 'attending', false)} 
                  className="checkbox"
                />
              </div>
              <button className="remove_guest_button" onClick={(e) => removeGuest(index)}>
                <p className="remove_guest_p">- remove guest</p>
              </button>
            </div>
          </li>
          ))}
        </ul>
        <button onClick={addGuest} className="add_guest_button">
          <p className="add_guest_p">+ add guest</p>
        </button>
        <div style={{marginTop:'2em'}}>
          <button onClick={toggleModal} className="submit_rsvp_button">
            <p className="submit_rsvp_p">Submit</p>
          </button>
        </div>

        {/* 
        The following code is adapted from an online tutorial found at
        https://www.youtube.com/watch?v=9DwGahSqcEc
        with source code found at
        https://github.com/ecole-du-web/react-modal/blob/main/src/Components/Modal/Modal.css
        Thanks for saving me a ton of time
        */}
        {doneResponding && (
        <div className="modal">
          <div className="overlay" onClick={toggleModal}></div>
            <div className="modal-content">
              <div style={{display:'flex', flexDirection:'row', justifyContent: 'space-evenly', alignItems:'center', flexFlow: 'wrap'}}>
                <ul style={{marginBottom: '4em', padding: 0}}> <p className="rsvp_p">You entered:</p>
                  {guestInfo.map((guest, index) => (
                    <li key={index} style={{listStyleType: 'none'}}>
                      <div style={ {display:'flex', flexDirection:'row', alignItems:'center'} }>
                        {!guest.attending && 
                        <div style={{marginLeft:'2em'}}>
                          <p className="rsvp_p" style={{marginBottom:'0.5em'}}>{guest.name} as 'not attending'</p>
                        </div>
                        }
                        {guest.attending && 
                        <div style={{marginLeft:'2em'}}>
                          <p className="rsvp_p" style={{marginBottom:'0.5em'}}>{guest.name} as 'attending'</p>
                        </div>
                        }
                      </div>
                    </li>
                  ))}
                </ul>
                <div style={{display:'flex', flexDirection:'column', justifyContent: 'center'}}>
                  <button className="submit_rsvp_button"  style={{alignSelf:'center'}} onClick={toggleModal}>
                    <p className="submit_rsvp_p">Go back</p>
                  </button>
                  <button className="submit_rsvp_button"  style={{alignSelf:'center'}} onClick={submitRsvp}>
                    <p className="submit_rsvp_p">Submit RSVPs</p>
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}

      </div>
    </>
  );
}

export default RsvpForm;