import "./Rsvp.css"
import MyMenu from "../../MyMenuComp/MyMenu.js";
import { useState } from "react";
import { useNavigate } from 'react-router-dom';

function Rsvp() {
  const validator = require("email-validator");
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [responses, setResponses] = useState([{ name: "", attending: true }]);
  const [validName, setValidName] = useState(true);
  const [validEmail, setValidEmail] = useState(true);
  const [emailFound, setEmailFound] = useState(false);
  
  function handleEmailChange(input) {
    setEmail(input.target.value);
  }

  function handleNameChange(input) {
    setName(input.target.value);
  }

  function navigateToRsvpForm() {
    navigate('/rsvp-form', { state: { email: email.toLowerCase(), name: name, responses: responses, fromRsvp: true } });
  }


  function handleCheckEmail() {
    setValidName(true);
    setValidEmail(true);
    let validInput = true;
    if (name === "") { 
      setValidName(false);
      validInput = false;
    }
    if (!validator.validate(email)) {
      setValidEmail(false);
      validInput = false;
    }
    if (!validInput) { 
      return; 
    }
    const data = { dataEmail: email.toLowerCase() };
    const request = new Request('/api/emailChecker', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    });
    const func = async () => {
      fetch(request)
        .then(response => response.json())
        .then(data => {
          if (Object.keys(data).length !== 0) {
            setResponses(data[0].guestlist);
            setEmailFound(true);
          }
          else {
            navigateToRsvpForm();
          }
        })
        .catch(error => {
          console.error(error);
        });
    }
    func();
  }

  function toggleModal() {
    setResponses([{ name: "", attending: true }])
    setEmailFound(!emailFound);
  }

  return (
    <>
      <style>{`body { background-color: var(--purple); }`}</style>
      <div className='header_div'>
        <h2 className='header_text_rsvp'>Rsvp</h2>
        <MyMenu pageName="rsvp"/>
      </div>
      <div className="body_div">
        <p className="rsvp_p">
          Please reply by April 1<sup>st</sup>
        </p>
        <p className="rsvp_p">
          Please enter your name and email:
        </p>
        <div className="spacer">
          <div className="entry_div">
            <p className="entry_p" style={{margin: 0}}>Name:</p>
            <input type='text' value={name} onChange={handleNameChange} className="rsvp_text_input"/>
          </div>
          <div className="entry_div">
            <p className="entry_p" style={{margin: 0}}>Email:</p>
            <input type='email' value={email} onChange={handleEmailChange} className="rsvp_text_input"/>
          </div>
          {!validName && (<p className="error_p" style={{textAlign: 'center'}}>Please enter a valid name</p>)}
          {!validEmail && (<p className="error_p" style={{textAlign: 'center'}}>Please enter a valid email</p>)}
        </div>
        <button onClick={handleCheckEmail} className="start_rsvp_button">
          <p className="start_rsvp_p">Start RSVPs</p>
        </button>

        {/* 
        The following code is adapted from an online tutorial found at
        https://www.youtube.com/watch?v=9DwGahSqcEc
        with source code found at
        https://github.com/ecole-du-web/react-modal/blob/main/src/Components/Modal/Modal.css
        Thanks for saving me a ton of time
        */}
        {emailFound && (
        <div className="modal">
          <div className="overlay" onClick={toggleModal}></div>
            <div className="modal-content">
              <p className="rsvp_p">
                It looks like this email has already been used to submit a response:
              </p>
              <div style={{display:'flex', flexDirection:'row', justifyContent: 'space-evenly', alignItems:'flex-start', flexFlow: 'wrap'}}>
                <ul style={{marginBottom: '4em', padding: 0}}>
                  {responses.map((guest) => (
                    <li key={guest.id} style={{listStyleType: 'none'}}>
                      <div style={ {display:'flex', flexDirection:'row', alignItems:'center'} }>
                        {!guest.attending && 
                          <div style={{marginLeft:'2em'}}>
                            <p className="rsvp_p" style={{margin: 0}}>{guest.name} is not attending</p>
                          </div>
                        }
                        {guest.attending && 
                          <div style={{marginLeft:'2em'}}>
                            <p className="rsvp_p" style={{margin: 0}}>{guest.name} is attending</p>
                          </div>
                        }
                      </div>
                    </li>
                  ))}
                </ul>
                <div style={{display:'flex', flexDirection:'column', justifyContent: 'center'}}>
                  <div style={{display:'flex', flexDirection:'column', justifyContent: 'center'}}>
                  <button className="start_rsvp_button" onClick={navigateToRsvpForm}>
                    <p className="start_rsvp_p">Edit response</p>
                  </button>
                  </div>
                  <div style={{display:'flex', flexDirection:'column', justifyContent: 'center'}}>
                  <button className="start_rsvp_button" onClick={toggleModal}>
                    <p className="start_rsvp_p">Go back</p>
                  </button>
                  </div>
                </div>
              </div>
            </div>
        </div>
        )}
      </div>
    </>
  );
}

export default Rsvp;