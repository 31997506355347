import './App.css';
import { Outlet } from "react-router-dom";
import { useLocation } from 'react-router'

function App() {
  const location = useLocation();

  return (
    <>
      <div className="App">
        <div className='App_holder'>
          <Outlet />
        </div>
      </div>
      {location.pathname === "/" &&
      <footer style={{fontFamily: '"Onest", serif', textAlign: 'center', marginTop: '3em'}}>
        Special thanks to <a className="footer_ref" href="https://www.flaticon.com/authors/rutmer-zijlstra" title="Rutmer Zijlstra" target="_blank" rel="noreferrer"> Rutmer Zijlstra </a> from <a className="footer_ref" href="https://www.flaticon.com/" title="Flaticon" target="_blank" rel="noreferrer">'www.flaticon.com'</a> for providing free icons for this site.
      </footer>
      }
    </>
    );
}

export default App;
