import "./Rsvp.css"
import MyMenu from "../../MyMenuComp/MyMenu.js";

const RsvpError = () => {
    return (
      <>
        <style>{`body { background-color: var(--purple); }`}</style>
        <div className='header_div'>
          <h2 className='header_text_rsvp'>Uh Oh!</h2>
          <MyMenu pageName="rsvp"/>
        </div>
        <div className="body_div">
          <p className="rsvp_p" style={{textAlign: 'left'}}>
            If you reached this page, it means that there was an error entering your response.
          </p>
          <p className="rsvp_p" style={{textAlign: 'left'}}>
            Please try submitting your RSVP again. If this issue persists, please contact Madisen or Beckett directly.
          </p>
          <p className="rsvp_p" style={{textAlign: 'center'}}>
            Sorry for the inconvenience!
          </p>
        </div>
      </>
    )
  };
  
  export default RsvpError;